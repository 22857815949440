<template>
  <SelectPlay name="locales" :value="pais_code" @input="changeCountry($event)" :options="lists" />
</template>

<script>
import { EventBus } from "@/main.js";

import MemberMetaService from "@/services/resources/MemberMetaService";
const serviceMemberMeta = MemberMetaService.build();

export default {
  name: "Locales",
  props: ["off"],
  data() {
    return {
      pais: "",
      paises: false,
      pais_nome: "",
      pais_code: "",
      search: "",
      showSelectedImg: false,
      lists: [],
    };
  },
  mounted() {
    this.fetchCountry();
    this.$root.$on("getCountry", () => {
      this.fetchCountry();
    });
  },
  methods: {
    fetchCountry() {
      this.lists = [
        { id: "BR", sigla: "BR", sub: "Portugues", label: "Português - Brasil", pais: "Português - Brasil" },
        { id:'US', sigla:'US', sub:'Inglês', label:'Inglês' , pais:'Inglês'},
        { id: "ES", sigla: "ES", sub: "Espanhol", label: "Espanhol", pais: "Espanhol" }
      ];

      serviceMemberMeta
        .search("keys[]=pais")
        .then((resp) => {
          if (resp.pais !== undefined) {
            if (resp.pais === null) {
              this.selectCountry("BR");
            } else {
              var myArray = this.lists;
              var result = myArray.filter((x) => x.sigla === resp.pais);
              if (JSON.stringify(result) === "[]") {
                this.setCountry("BR", "Brasil");
              } else {
                this.setCountry(result[0].sigla, result[0].pais);
              }
            }
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    setCountry(code, lang) {
      if (this.pais_code != code) {
        this.pais_code = code;
        this.pais_nome = `${lang}`;
      }
      let data = {
        locale: code,
      };
      EventBus.$emit("atualizaPais", data);
      this.selectCountry(code);
      this.paises = false;
    },
    changeCountry(code) {
      if (this.pais_code != code) {
        this.pais_code = code;
        const lang = this.lists.filter((item) => item.sigla === code)[0].pais;
        this.pais_nome = `${lang}`;
      }
      let data = {
        locale: code,
      };
      EventBus.$emit("atualizaPais", data);
      this.selectCountry(code);
      this.paises = false;

      if (this.$route.path !== "/minha-conta") {
        serviceMemberMeta
          .postID({ id: "pais", value: code })
          .then(() => {
            this.$bvToast.toast(this.$t('locales.msg'), {
            title: this.$t('locales.title'),
              variant: "success",
              autoHideDelay: 5000,
              appendToast: true,
            });
          });
      }
    },
    selectCountry(locale) {
      const localeMap = {
        US: "en",
        ES: "es",
      };

      const defaultLocale = "pt";
      this.$i18n.locale = localeMap[locale] || defaultLocale;
    },
  },
  created() {
    this.fetchCountry();
  },
};
</script>
