<template>
  <Layout>
    <EditProfile />
  </Layout>
</template>

<script>
import Layout from "@/layout/Layout.vue";
import EditProfile from "@/components/pages/my-account/EditProfile.vue";

export default {
  components: {
    Layout,
    EditProfile,
  },
};
</script>